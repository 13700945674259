import { LogicOperator } from '../../js/generated/enums/LogicOperator';
import { LogicFeature } from '../../js/generated/enums/LogicFeature';
import { QuestionType } from '../../js/generated/enums/QuestionType';

export const QueryState = Object.freeze({
  Idle: 'idle',
  Loading: 'loading',
  Succeeded: 'succeeded',
  Failed: 'failed',
  Rejected: 'rejected',
  Submitting: 'submitting'
})

export function isIdleOrSuccessState (queryState) {
  return queryState === QueryState.Idle || queryState === QueryState.Succeeded
}

export function isInProgressState (queryState) {
  return queryState === QueryState.Loading || queryState === QueryState.Submitting
}

export function isErrorState (queryState) {
  return queryState === QueryState.Failed || queryState === QueryState.Rejected
}

export function getCurrentSeconds () {
  return new Date().getTime() / 1000
}

export function getMatchingMultipleAnswerForLogic (answerIds, questionAnswers, nodeValue, fallbackAnswerId = null) {
  const matchingAnswerId = questionAnswers.filter(elem => elem.content === nodeValue)[0]?.id.toString() ?? null
  return matchingAnswerId && answerIds.includes(matchingAnswerId) ? matchingAnswerId : answerIds[0] ?? fallbackAnswerId
}

export function evaluateNodeInnerLogic (node, question, respondentAnswer) {
  const skipped = (respondentAnswer.skipped || question.hideFromLogic)
  const isMultipleAnswerQuestion = question.type === QuestionType.ChooseAllThatApply
  const isMultipleAnswerLogic = isMultipleAnswerQuestion && (node.operator === LogicOperator.Excludes || node.operator === LogicOperator.Includes)
  const answerId = skipped ? null : (isMultipleAnswerLogic ? getMatchingMultipleAnswerForLogic(respondentAnswer.answerIds, question.answers, node.value, respondentAnswer.answerId) : respondentAnswer.answerIds[0] ?? respondentAnswer.answerId)
  const staticAnswerText = answerId ? (question.answers.filter(elem => elem.id.toString() === answerId)[0]?.content ?? null) : null
  const answerText = staticAnswerText || (skipped ? '' : respondentAnswer.additionalAnswer)
  console.debug('Logic comparison.', { answerText, answerId, question, respondentAnswer, node })
  if (node.operator === LogicOperator.NotEqualTo) {
    return answerText !== node.value || (isMultipleAnswerQuestion && !skipped && (respondentAnswer.answerIds.length > 1))
  } else if (node.operator === LogicOperator.Excludes) {
    return answerText !== node.value
  } else if (node.operator === LogicOperator.Equals) {
    return answerText === node.value && (!isMultipleAnswerQuestion || skipped || (respondentAnswer.answerIds.length <= 1))
  } else {
    return answerText === node.value
  }
}

/**
 *
 * @param node
 * @param getNodeFromId (childId) => toUpdateLogicMap.get(childId)
 * @param getQuestionFromId (questionId) => selectQuestionById(state, questionId)
 * @param getRespondentAnswerFromId (questionId, question) => selectRespondentAnswerById(state, node.questionId)
 * @returns {boolean}
 */
export function resolveNode (node, getNodeFromId, getQuestionFromId, getRespondentAnswerFromId) {
  let childValues = false
  if (node.children?.length) {
    for (const childId of node.children) {
      const child = getNodeFromId(childId)
      if (!child) {
        console.error('Unable to find logic with id matching expected child logic id.', childId, node)
        continue
      }
      childValues = childValues || resolveNode(child, getNodeFromId, getQuestionFromId, getRespondentAnswerFromId)
      if (childValues) {
        break
      }
    }
  } else {
    childValues = true
  }
  if (!childValues) {
    return false
  }
  const question = getQuestionFromId(node.questionId)
  const respondentAnswer = getRespondentAnswerFromId(node.questionId, question)
  if (!respondentAnswer?.id || !question?.id) {
    console.error('Unable to find respondentAnswer|question with id matching expected logic id.', node.questionId, respondentAnswer?.id, question?.id, node)
    return false
  }
  return evaluateNodeInnerLogic(node, question, respondentAnswer)
}

export function evaluateLogicForQuestion (rootLogicIds, getNodeFromId, getQuestionFromId, getRespondentAnswerFromId) {
  const featureMap = new Map()
  for (const nodeId of rootLogicIds) {
    const node = getNodeFromId(nodeId)
    if (!node) {
      console.error('Unable to find logic with id matching expected root logic id.', nodeId, rootLogicIds)
      continue
    }
    featureMap.set(node.feature, featureMap.get(node.feature) ? true : resolveNode(node, getNodeFromId, getQuestionFromId, getRespondentAnswerFromId))
  }
  let showQuestion = null
  let hideQuestion = null
  for (const [feature, result] of featureMap.entries()) {
    if (feature === LogicFeature.Hide) {
      hideQuestion = result
    } else if (feature === LogicFeature.Show) {
      showQuestion = result
    }
  }
  const hideFromLogic = (showQuestion === null) ? !!hideQuestion : !showQuestion
  console.debug('Evaluated logic result for question.', { hideFromLogic, rootLogicIds })
  return hideFromLogic
}
